.card-text{
    font-size: 34px;
    font-weight: 600;
}
.card{
    cursor: pointer;
    width: 100%;
}
.contents{
    display: grid;
    grid-template-columns: 1fr 1fr;

}
.input-container{
    display: flex;
    flex-direction: column;
    border:1px solid #4444ff;
    border-radius: 5px;
    position: relative;
    height:50px;
  
    input{
      border:none;
      position: absolute;
      top:0;
      left: 2%;
      right: 0;
      bottom: 0;
      padding-top:15px;
  
      &:focus{
        outline: none;
      }
      // &:{
      //   color:red;
      // }
    }
  
    label{
      position: absolute;
      z-index: 2;
      top:50%;
      transform: translateY(-50%);
      left:2%;
  
      &.shrink{
        font-size: 12px;
        top:20%;
      }
    }
  
  
  }